<template>
  <div id="container">
    <Top :show-user="true"/>
    <div class="body">
      <div class="title">
        Профиль пользователя
      </div>
      <div class="block">
        <div class="status-line">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Имя пользователя (логин)
            </div>
            {{ user?.username }}
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Имя
            </div>
            {{ user?.first_name || '-' }}
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Фамилия
            </div>
            {{ user?.last_name || '-' }}
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Адрес электронной почты
            </div>
            {{ user?.email || '-' }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top";

export default {
  name: 'Profile',
  components: {
    Top
  },
  computed: {
    user() {
      return window.drfUser?.id ? window.drfUser : null
    }
  }
}
</script>

<style scoped>
.body {
  margin-top: 5.2rem;
}

.title {
  font-size: 1.3rem;
  color: #3ebaff;
  padding: 1rem 1rem 0.5rem 2rem;
}

.block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

.status-line {
  display: flex;
  margin: 0.5rem 0;
}

.status-item {
  margin-right: 2rem;
}

.status-item-name {
  margin-bottom: 0.3rem;
}
</style>
