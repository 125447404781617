<template>
  <table>
    <thead>
    <tr>
      <th class="group pax-th" style="width: 15%">
        Резервная группа
      </th>
      <th class="pax-th" style="width: 20%">
        Тип расчета
      </th>
      <th class="pax-th" style="width: 10%">
        Отчетная дата
      </th>
      <th class="pax-th" style="width: 10%">
        Дата расчета
      </th>
      <th class="pax-th" style="width: 15%">
        Ответственный
      </th>
      <th class="pax-th" style="width: 20%">
        Статус расчета
      </th>
      <th class="pax-th" style="width: 10%">
        Результат
      </th>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="type" colspan="7">
        {{ title }}
      </td>
    </tr>
    <GridLine v-for="item in items" :item="item" :key="item.id" @change-state="changeRowState"/>
    <tr v-if="!items.length">
      <td class="no" colspan="7">
        Расчеты не найдены
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import GridLine from '@/components/Dashboard/GridLine'

export default {
  components: {
    GridLine
  },
  props: ['title', 'items'],
  methods: {
    changeRowState(id) {
      this.$emit('change-state', id)
    }
  }
}
</script>

<style scoped>
table {
  border-spacing: 0.5rem;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

th {
  text-align: center;
  padding: 0 0.2rem;
  font-weight: normal;
}

.group {
  text-align: left;
  padding-left: 0;
}

.type {
  text-align: left;
  font-weight: bold;
}

.no {
  padding-left: 1rem;
  padding-bottom: 0.3rem;
}
</style>
