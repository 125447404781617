<template>
  <div class="top">
    <img src="@/assets/img/kept_logo_1.svg" alt="Kept">
    <UserInfo v-if="showUser"/>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo";

export default {
  name: 'Top',
  components: {
    UserInfo
  },
  props: ['showUser']
}
</script>

<style scoped>
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem 1rem 3rem;
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 5.2rem;
  position: fixed;
  z-index: 1000;
  top: 0;
}
</style>
