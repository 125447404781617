<template>
  <div class="filters">
    <div class="filter">
      <label class="pax-title">Резервная группа</label>
      <select @change="$emit('applyGroup', $event.target.value)">
        <option v-for="[key, value] in groups" :value="key" :key="key" :selected="activeGroup === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title">Тип расчета</label>
      <select @change="$emit('applyType', $event.target.value)">
        <option v-for="[key, value] in types" :value="key" :key="key" :selected="activeType === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title">Отчетная дата</label>
      <select @change="$emit('applyReportDate', $event.target.value)">
        <option v-for="[key, value] in reportDates" :value="key" :key="key" :selected="activeReportDate === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title">Статус расчета</label>
      <select @change="$emit('applyStatus', $event.target.value)">
        <option v-for="[key, value] in statuses" :value="key" :key="key" :selected="activeStatus === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter">
      <label class="pax-title">Валюта</label>
      <select @change="$emit('applyCurrency', $event.target.value)">
        <option v-for="[key, value] in currencies" :value="key" :key="key" :selected="activeCurrency === key">
          {{ value }}
        </option>
      </select>
    </div>
    <div class="filter clear">
      <label class="pax-title">Очистить</label>
      <a @click="$emit('clearAll')" class="clear">
        <img src="@/assets/img/filter_to_clear.svg" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filter',
  props: ['groups', 'activeGroup', 'types', 'activeType', 'reportDates', 'activeReportDate',
    'statuses', 'activeStatus', 'currencies', 'activeCurrency'],
  emits: ['applyGroup', 'applyType', 'applyReportDate', 'applyStatus', 'applyCurrency', 'clearAll']
}
</script>

<style scoped>
.filters {
  display: flex;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.filter {
  margin-right: 0.7rem;
}

label {
  display: block;
  margin-right: 0.7rem;
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  color: #6b6f77;
}

select {
  width: 12rem;
}

div.clear {
  margin-bottom: -1rem;
}

a.clear {
  cursor: pointer;
}
</style>
