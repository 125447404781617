<template>
  <div id="container">
    <Top :show-user="true"/>
    <div class="body">
      <div class="title">
        Страница не найдена
      </div>
      <div class="block">
        <div class="error">
          Данная страница находится в разработке или Вы перешли по несуществующему адресу
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top";

export default {
  name: 'Page404',
  components: {
    Top
  }
}
</script>

<style scoped>
.body {
  margin-top: 5.2rem;
}

.title {
  font-size: 1.3rem;
  color: #3ebaff;
  padding: 1rem 1rem 0.5rem 2rem;
}

.block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

.error {
  color: red;
}
</style>
