<template>
  <div class="chart">
    <Line :data="data" :options="options"/>
  </div>
</template>

<script>
import {CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title} from 'chart.js'
import {Line} from 'vue-chartjs'

ChartJS.register(CategoryScale, Legend, LinearScale, LineElement, PointElement, Title)

export default {
  name: 'LineChart',
  components: {Line},
  props: {
    title: String,
    displayX: {
      type: Boolean,
      default: true,
    },
    displayY: {
      type: Boolean,
      default: true,
    },
    data: Object
  },
  data() {
    return {
      options: {
        responsive: true,
        scales: {
          x: {
            grid: {
              display: this.displayX,
            }
          },
          y: {
            grid: {
              display: this.displayY,
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              boxHeight: 0,
            }
          },
          title: {
            display: true,
            text: this.title,
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.chart {
  padding: 2rem 2rem 0 0;
  width: 600px;
}
</style>
