<template>
  <div class="user-container">
    <div class="parent">
      <router-link class="name" to="/profile" @mouseover="showMenu">
        {{ displayName }}
      </router-link>
      <div v-if="isOpen" class="menu" @mouseleave="hideMenu">
        <router-link class="menu-item" to="/profile">
          {{ displayName }}
        </router-link>
        <a class="menu-item" @click="logout">
          Выход
        </a>
      </div>
    </div>
    <div class="icons">
      <router-link v-if="hasPerm('config')" class="icon" to="/settings">
        <img src="@/assets/img/settings.svg" alt="">
      </router-link>
      <router-link class="icon" to="/">
        <img src="@/assets/img/home.svg" alt="">
      </router-link>
      <a class="icon" @click="$router.go(-1)">
        <img src="@/assets/img/back.svg" alt="">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserInfo',
  data() {
    return {
      isOpen: false,
    }
  },
  computed: {
    displayName() {
      const user = window.drfUser
      return user?.id ? `${user.first_name} ${user.last_name}`.trim() || user.username || '' : ''
    }
  },
  methods: {
    hasPerm(permission) {
      return window.drfUser.permissions.includes('api.' + permission)
    },
    showMenu() {
      this.isOpen = true
    },
    hideMenu() {
      this.isOpen = false
    },
    logout() {
      this.$http
          .post('/logout/')
          .finally(() => {
            window.drfUser = null
            this.$router.replace('/login')
          })
    }
  }
}
</script>

<style scoped>
.user-container {
  padding-top: 1rem;
}

.parent {
  position: relative;
}

.name {
  display: block;
  text-decoration: none;
  color: #0074b5;
  margin-bottom: 0.3rem;
  white-space: nowrap;
  width: 100%;
}

.icons {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
}

.icon {
  cursor: pointer;
  margin-right: 0.8rem;
}

.icon > img {
  width: 1.5rem;
  height: 1.5rem;
}

.menu {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  padding: 0.5rem 0.5rem 0 0.5rem;
  background-color: #fafafa;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  width: calc(1rem + 100%);
}

.menu-item {
  display: block;
  text-decoration: none;
  color: #0074b5;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  cursor: pointer;
}

.menu-item:hover {
  color: #3ebaff;
}
</style>
