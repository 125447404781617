<template>
  <tr>
    <td class="group">
      <input :id="'id_' + item.id" type="checkbox" @change="$emit('change-state', item.id)" class="checkbox">
      <label :for="'id_' + item.id">{{ item.group_name }}</label>
    </td>
    <td>
      <router-link :to="getUrl(item.type)" class="report">
        {{ item.type_name }}
      </router-link>
    </td>
    <td>
      {{ item.report_date }}
    </td>
    <td>
      {{ item.calc_date }}
    </td>
    <td>
      {{ item.author_name }}
    </td>
    <td>
      {{ item.status_name }}
    </td>
    <td>
      <router-link :to="getUrl(199)">
        <img src="@/assets/img/result.svg" alt="">
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    getUrl(type) {
      return '/reports/' + this.item.group + '/' + type + '/' + this.item.report_date
    }
  }
}
</script>

<style scoped>
td {
  text-align: center;
  padding-bottom: 0.2rem;
  vertical-align: center;
}

.group {
  text-align: left;
  padding-left: 1rem;
}

.checkbox {
  margin-right: 0.5rem;
}

.report {
  color: #3ebaff;
  white-space: nowrap;
}
</style>
