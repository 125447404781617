<template>
  <div class="container">
    <Top :show-user="false"/>
    <div class="body">
      <div class="block">
        <div class="title">
          Вход в систему
        </div>
        <div class="label">
          Имя пользователя
        </div>
        <div>
          <input type="text" ref="username" required class="user" test-id="username_input">
        </div>
        <div class="label">
          Пароль
        </div>
        <div>
          <input type="password" ref="password" required class="pass" test-id="password_input">
        </div>
        <div class="error">
          {{ error }}
        </div>
        <div class="report">
          <a class="pax-button" @click="login" test-id="login_button">Войти</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Top from "@/components/Top";

export default {
  name: 'Login',
  components: {
    Top
  },
  data() {
    return {
      error: null
    }
  },
  methods: {
    login() {
      const username = this.$refs.username.value.trim()
      const password = this.$refs.password.value

      if (!(username || password)) {
        this.error = 'Введите имя пользователя и пароль'
        return
      } else if (!username) {
        this.error = 'Введите имя пользователя'
        return
      } else if (!password) {
        this.error = 'Введите пароль'
        return
      }

      this.$http.post('/login/',
          {
            'username': username, 'password': password
          }
      ).then(response => {
        window.drfUser = response.data
        this.$router.replace('/')
      }).catch(error => {
        window.drfUser = null
        this.error = 'Имя пользователя и/или пароль указаны неверно'
        console.error('Login: ERROR: ', error)
      })
    }
  }
}
</script>

<style scoped>
.body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('@/assets/img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
}

.block {
  background-color: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  border: 1px solid #cccccc;
  border-radius: 10px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -1rem -1rem 1rem -1rem;
  height: 2.5rem;
  font-size: 1.2rem;
  color: #0074b5;
}

.label {
  margin-bottom: 0.2rem;
}

.user {
  width: 16rem;
  margin-bottom: 0.5rem;
  padding: 0.2rem;
}

.pass {
  width: 16rem;
  padding: 0.2rem;
}

.report {
  margin-top: 1rem;
  text-align: center;
}

.pax-button {
  width: 8rem;
  padding: 0.5rem;
  text-transform: none;
  background-color: #0074b5;
  border-radius: 7px;
}

.error {
  color: darkred;
  text-align: center;
  margin-top: 1rem;
  width: 16rem;
}
</style>
