import {createApp} from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'
import "./assets/main.css";

const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '/api':'https://localhost:8642/api', // localhost
  // baseURL: 'https://51.250.71.173:8642', // actool
  // baseURL: 'https://158.160.20.110:8642', // actoolmvp
  xsrfHeaderName: 'X-CSRFToken',
  xsrfCookieName: 'csrftoken',
  withCredentials: true,
})

const numberOptions = {useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2}
const numberFormat = new Intl.NumberFormat('ru-RU', numberOptions)

const intOptions = {useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0}
const intFormat = new Intl.NumberFormat('ru-RU', intOptions)

http.get('/profile/')
    .then(response => {
      window.drfUser = response.data
    })
    .catch(() => {
      window.drfUser = null
    })
    .finally(() => {
      const app = createApp(App)
      app.config.unwrapInjectedRef = true
      app.config.globalProperties.$http = http
      app.config.globalProperties.$numberFormat = numberFormat
      app.config.globalProperties.$intFormat = intFormat
      app.config.globalProperties.$divider = {value: 1}
      app.config.globalProperties.$debug = process.env.NODE_ENV === 'production' ? false : true
      app.use(router).mount('#app')
    })
