<template>
  <div class="container">
    <div class="types">
      <div :class="isType([])">
        <router-link to="/dashboard" test-id="calculations_link">
          Свод по расчетам
        </router-link>
      </div>
      <div :class="isType([101, 106, 107, 108, 109, 110, 111, 112, 113])">
        <router-link to="/reports/101" test-id="grades_link">
          Наилучшие оценки
        </router-link>
      </div>
      <div :class="isType([102, 114])" test-id="risk_link">
        <router-link to="/reports/102">
          Рисковые поправки
        </router-link>
      </div>
      <div :class="isType([199])" test-id="result_link">
        <router-link to="/reports/199">
          Обзор результата
        </router-link>
      </div>
      <div :class="isType([103, 104, 116, 117, 127, 128, 129, 130, 118, 119, 131, 132])" test-id="flow_link">
        <router-link to="/reports/103">
          Денежные потоки
        </router-link>
      </div>
      <div :class="isType([115, 120, 121, 122, 123, 124, 125, 126])">
        <router-link to="/reports/115">
          Перестрахование
        </router-link>
      </div>
    </div>
    <div class="user">
      <UserInfo/>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/components/UserInfo";

export default {
  name: 'TopTypeSelector',
  components: {
    UserInfo
  },
  methods: {
    isType(types) {
      const param = Number(this.$route.name.substr(-3))
      return types.includes(param) || !(types.length || param) ? 'active' : ''
    },
  }
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding-top: 0.5rem;
}

.types {
  display: flex;
}

.types div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #6b6f77;
  padding: 0.5rem 1rem;
}

.types a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}

.types div.active {
  border-bottom: 2px solid #0074b5;
  border-radius: 2px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.types div.active a {
  color: #0074b5;
}

.user {
  margin: 0 1rem;
}
</style>
