<template>
  <fieldset class="container">
    <router-view/>
  </fieldset>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style scoped>
.container {
  border: none;
}
</style>
