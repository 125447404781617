<template>
  <div id="container">
    <Top :show-user="true"/>
    <div class="body">
      <div class="title">
        Настройки системы (версия 4.9.6)
      </div>
      <div class="block">
        <table>
          <thead>
          <tr>
            <th :colspan="users.length + 1">
              Управление резервными группами
            </th>
          </tr>
          <tr>
            <th>
              РГ
            </th>
            <th v-for="user in users" :key="user">
              {{ user }}
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="group in groups" :key="group.id" class="data-row">
              <td class="period">
                {{ group.name }}
              </td>
              <td class="check" v-for="user in users" :key="user">
                <input type="checkbox" checked>
              </td>
              <td v-if="![1, 2].includes(group.id)">
                <button @click="deleteGroup(group.id)">Удалить</button>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="switchGroupsEditModalState">Добавить резервную группу</button>
      </div>

      <div class="block">
        <table>
          <thead>
          <tr>
            <th colspan="4">
              Настройки шаблонных методов
            </th>
          </tr>
          <tr>
            <th>
              РГ
            </th>
            <th>
              Тип расчета
            </th>
            <th>
              Шаблонный метод
            </th>
            <th>
              Параметры данных
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="templates.length" v-for="t in templates" :key="t.id" class="data-row">
            <td>
              {{ t.group }}
            </td>
            <td>
              {{ t.type }}
            </td>
            <td>
              {{ t.method }}
            </td>
            <td>
              {{ t.period }},<br>
              {{ t.dimension }} период,<br>
              {{ t.base }},<br>
              {{ t.calc_type }}
            </td>
          </tr>
          <tr v-else>
            <td colspan="4" style="text-align: center">
              Нет шаблонных методов
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="block">
        <table>
          <thead>
          <tr>
            <th colspan="4">
              Удаление всех расчетов
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="4" style="text-align: center">
              <button @click="switchClearModalState">Удалить все</button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <teleport to="body">
    <Modal :show="showGroupsEditModal" :controlsAvaliable="true">
      <template #header>
        <div class="modal-header">Добавить новую резервную группу</div>
      </template>
      <template #body>
        <h3 v-if="groupAddErrorMessage">{{ groupAddErrorMessage }}</h3>
        <div class="modal-group-name-input">
          <label for="groupName">Название резерной группы:</label>
          <input type="text" id="groupName" v-model="groupName">
        </div>
      </template>
      <template #footer>
        <button @click="addGroup">Добавить</button>
        <button @click="switchGroupsEditModalState">Отмена</button>
      </template>
    </Modal>
  </teleport>

  <teleport to="body">
    <Modal :show="showClearModal" :controlsAvaliable="true">
      <template #header>
        <div class="modal-header">Вы действительно хотите удалить все расчеты?</div>
      </template>
      <template #body>
        Это действие нельзя отменить
      </template>
      <template #footer>
        <button @click="clearReports">Удалить</button>
        <button @click="switchClearModalState">Отмена</button>
      </template>
    </Modal>
  </teleport>
</template>

<script>
import Top from "@/components/Top";
import Modal from "@/components/Modal.vue";

export default {
  name: 'Settings',
  components: {
    Top,
    Modal
  },
  data() {
    return {
      users: [],
      groups: [],
      templates: [],
      showGroupsEditModal: false,
      groupName: '',
      groupAddErrorMessage: '',
      showClearModal: false,
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.$http
          .get(`/settings`)
          .then(response => {
            this.users = response.data.users
            this.groups = response.data.groups
            this.templates = response.data.templates
            console.log('Settings->loaded(): done')
          })
          .catch(error => console.error('Settings->loaded(): ERROR: ' + error))
    },
    switchGroupsEditModalState() {
      this.showGroupsEditModal = !this.showGroupsEditModal;
    },
    switchClearModalState() {
      this.showClearModal = !this.showClearModal;
    },
    addGroup() {
      this.$http
        .post(`/settings/`, {name: this.groupName})
        .then(response => {
          console.log(`Adding group`)
          if(response.status === 200){
            this.groups.push(response.data);
            this.switchGroupsEditModalState();
            this.groupName = null;
          }
        })
        .catch(error => {
          this.groupAddErrorMessage = 'Группа с таким названием уже создана';
          setTimeout(()=>{
            this.groupAddErrorMessage = '';
          }, 3000)
          console.log('Error in group edit', error);
        })
    },
    clearReports() {
      this.$http
        .post(`/reports/clear/`, {name: this.groupName})
        .then(response => {
          console.log(`Clearing reports`)
          if(response.status === 200){
            this.switchClearModalState();
          }
        })
        .catch(error => {
          console.log('Error in reports clear', error);
        })
    },
    deleteGroup(id) {
      this.$http
        .delete(`/settings/${id}/`,)
        .then(response => {
          if(response.status === 200){
            console.log(this.groups)
            this.groups = this.groups.filter((group) => {
              return group.id !== id
            })
          }
        })
        .catch(error => {
          console.log('Error in group edit', error);
        })
    }
  }
}
</script>

<style scoped>
.body {
  margin-top: 5.2rem;
}

.title {
  font-size: 1.3rem;
  color: #3ebaff;
  padding: 1rem 1rem 0.5rem 2rem;
}

.block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin: 0 1rem 1rem 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

table {
  border-collapse: collapse;
  margin: 1rem 0;
}

tr.data-row:nth-child(odd) {
  background-color: #d5e2ef;
}

th {
  padding: 0.2rem 0.5rem;
  border: 1px solid #aaa;
  font-size: 0.9rem;
  overflow-wrap: anywhere;
  color: #0074B5;
}

td {
  padding: 0.3rem;
  border: 1px solid #aaa;
  text-align: left;
  font-size: 0.8rem;
  overflow-wrap: anywhere;
}

td.period {
  text-align: center;
  min-width: 8rem;
  max-width: 8rem;
}

td.check {
  text-align: center;
}

td span {
  font-size: 0.8rem;
}

.modal-group-name-input{
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
</style>
