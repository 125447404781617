<template>
  <div class="container">
    <div class="block">
      <div class="top-buttons">
        <div class="top-left">
          <img src="@/assets/img/incremental_triangle.svg" alt="">
          <span v-if="calc.id">{{ calc.name }}</span>
        </div>
      </div>

      <div class="status-line">
        <div class="status-item">
          <div class="pax-th status-item-name">
            Период треугольника
          </div>
          {{ calc.period_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Количество периодов
          </div>
          {{ calc.dimension }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            База треугольника
          </div>
          {{ calc.base_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Метод расчета
          </div>
          {{ calc.method_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Тип убытков
          </div>
          {{ calc.type_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Версия данных
          </div>
          {{ calc.version }}
        </div>
      </div>

      <div v-if="calc.method === 102" class="status-line">
        <div class="status-item">
          <div class="pax-th status-item-name">
            Обнуляется отрицательный РПНУ
          </div>
          {{ calc.negative_to_zero ? 'Да' : 'Нет' }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Метод
          </div>
          {{ calc.bf_method_name }}
        </div>
      </div>

      <div class="total">
        Итого: {{ $numberFormat.format((calc.total || 0) / divider) }}
      </div>

      <div class="open-link">
        <a @click="changeState(true)" class="open-all">
          <img src="@/assets/img/incremental_more_detailed.svg" alt="">
          <span>развернуть все</span>
        </a>
        <a @click="changeState(false)" class="collapse-all">
          <img src="@/assets/img/incremental_more_detailed.svg" alt="">
          <span>свернуть все</span>
        </a>
      </div>
    </div>

    <template v-if="calc.id && [101, 106, 107, 108, 109, 110, 111, 112, 113].includes(type)">
      <div v-if="calc.method === 101" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7]]">
        <MatrixHolder :type="type" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"/>
      </div>
      <div v-else-if="calc.method === 102" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [8], [9], [10]]">
        <MatrixHolder :type="type" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"/>
      </div>
      <div v-else-if="calc.method === 103" class="block"
           v-for="pair in [[0], [1], [11], [12], [13], [14, 15], [16], [17], [18], [19], [20], [21], [22, 23], [24], [25], [26], [27], [28]]">
        <MatrixHolder :type="type" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"/>
      </div>
      <div v-else-if="calc.method === 104" class="block"
           v-for="pair in [[type === 101 ? 30 : 11]]">
        <MatrixHolder :type="type" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"/>
      </div>
    </template>
  </div>
</template>

<script>
import MatrixHolder from "@/components/Report/MatrixHolder";
import {computed} from "vue";

export default {
  data() {
    return {
      type: 101,
      calc: Object,
      state: {},
      divider: 1,
    }
  },
  provide() {
    return {
      children_status: null,
      state: computed(() => this.state),
      divider: computed(() => this.divider),
      reloadCells: {},
      canModify: false,
      lockStatus: false,
    }
  },
  components: {
    MatrixHolder
  },
  mounted() {
    this.loadCalc()
  },
  methods: {
    loadCalc() {
      const id = this.$route.params.id
      this.$http
          .get(`/calculations/${id}`)
          .then(response => {
            this.calc = response.data
            console.log(`OneCalc->loadCalculation(): calculation # ${id} is loaded`)
          })
          .catch(error => {
            console.error(`OneCalc->loadCalculation(): ERROR: ${error}`)
          })
    },
    changeState(state) {
      for (let i = 0; i < this.calc.matrix_set.length; i++) {
        const key = this.type + '_' + this.calc.order + '_' + this.calc.matrix_set[i].order
        this.state[key] = state
      }
    }
  }
}
</script>

<style scoped>
.top-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top-left {
  display: flex;
  align-items: center;
}

.top-left > img {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.top-left > span {
  color: #292d32;
  font-size: 1.1rem;
}

.total {
  margin: 1rem 0 1rem 2rem;
}

.block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;
}

.open-link {
  margin: 0 -1rem;
  border-top: 1px solid #c0c0c0;
  padding: 1rem 1rem 0 1rem;
}

.open-link > a {
  cursor: pointer;
}

.open-link > a > span {
  color: #3ebaff;
  padding-left: 1rem;
}

.open-link > a > img {
  width: 1rem;
}

.open-all {
  margin-right: 2rem;
}

.collapse-all > img {
  transform: rotate(180deg);
}

.status-line {
  display: flex;
  margin-left: 2rem;
}

.status-item {
  margin: 1rem 2rem 1rem 0;
}

.status-item-name {
  margin-bottom: 0.3rem;
}
</style>
