<template>
  <div class="container" :test-id='`matrix_${matrix.order}_in_calc_${calc ? calc.order : 0}`'>
    <div v-if="matrix.title" class="top-line">
      <div class="matrix-header" v-if="$debug">
        <img v-if="image" :src="require(`../../assets/img/${image}.svg`)" alt="">
        {{ matrix.order }} - {{ matrix.title }}
      </div>
      <div class="matrix-header" v-else>
        <img v-if="image" :src="require(`../../assets/img/${image}.svg`)" alt="">
        {{ matrix.title }} 
      </div>
      <div v-if="$route.name !== 'calc-101'" class="top-right">
        <div v-if="type === 199 && matrix.order === 3 && canModifyMatrix" class="top-right-item">
          <a :class="`top-button ${isOutdatedReport ? 'is-outdated' : ''}`" @click="emitRecalculate" :test-id='`recalculate_matrix_${matrix.order}_in_calc_${calc ? calc.order : 0}`'>Пересчитать РП</a>
        </div>
        <div class="top-right-item">
          <img src="@/assets/img/download.svg" alt="">
          <a class="top-button" @click="emitDownloadMatrix" :test-id='`download_matrix_${matrix.order}_in_calc_${calc ? calc.order : 0}`'>Скачать</a>
        </div>
      </div>
    </div>

    <template v-if="canModifyMatrix">
      <div v-if="helpString" class="coefficients">
        {{ helpString }}
      </div>
      <div v-else-if="matrix.selection" class="coefficients">
        *Необходимо выбрать коэффициенты
      </div>
    </template>

    <template v-if="calc && type === 116 && matrix.order === calc.matrix_set.length - calc.avaliable_cohorts.length || calc && type === 129 && matrix.order === calc.matrix_set.length - calc.avaliable_cohorts.length">
      <div class="filter">

        <div class="filter-item">
          <div class="filter-item-title">Когорта</div>
          <select @change="emitFieldChange('selected_cohort', $event.target.value)">
            <option v-for="year, index in calc.avaliable_cohorts" :value="year" :key="index"
                      :selected="year === calc.selected_cohort">
                {{ year }}
            </option>
          </select>
        </div>
        <div class="filter-item">
          <div class="filter-item-title">Тип бизнеса</div>
          <select @change="emitFieldChange('selected_business_type', $event.target.value)">
            <option value="1" :selected="calc.selected_business_type === 1">Действующий</option>
            <option value="2" :selected="calc.selected_business_type === 2">Новый</option>
          </select>
        </div>
      </div>
    </template>

    <div v-if="isResult && lockStatus?.locked === true" class="buttons">
      <div v-if="101 === children_status[matrix.associated_report_type] && hasPerm('validate')">
        <a @click="emitProcess('validate')">Отправить на проверку&nbsp;</a>
      </div>
      <div v-if="[101, 201].includes(children_status[matrix.associated_report_type]) && hasPerm('approve')">
        <a @click="emitProcess('approve')">Утвердить&nbsp;</a>
      </div>
      <div v-if="[201, 202, 203].includes(children_status[matrix.associated_report_type]) && hasPerm('deny')">
        <a @click="emitProcess('deny')">Отклонить утверждение&nbsp;</a>
      </div>
      <div v-if="301 === children_status[matrix.associated_report_type] && hasPerm('unlock')">
        <a @click="emitProcess('unlock')">Разблокировать&nbsp;</a>
      </div>
    </div>

    <div class="open-link" :key="state" :style="getStyle(calc?.order, matrix.order, false)">
      <a @click="toggle(calc?.order, matrix.order)">
        <img src="@/assets/img/incremental_more_detailed.svg" alt="">
        <span>подробнее</span>
      </a>
    </div>
    <div class="collapse-link" :key="state" :style="getStyle(calc?.order, matrix.order, true)">
      <a @click="toggle(calc?.order, matrix.order)">
        <img src="@/assets/img/incremental_more_detailed.svg" alt="">
        <span>свернуть</span>
      </a>
    </div>

    <div :key="state" :style="getStyle(calc?.order, matrix.order, true)">
      <Matrix :type="type" :matrix="matrix" :chart-data="calc?.chart_data"
              @reload-matrix-cells="emitReloadMatrixCells"/>
      <Matrix v-if="secondMatrix" :type="type" :matrix="secondMatrix" :chart-data="calc?.chart_data"
              @reload-matrix-cells="emitReloadMatrixCells" class="second"/>
    </div>
  </div>
</template>

<script>
import Matrix from "@/components/Report/Matrix";

export default {
  name: 'MatrixHolder',
  props: {
    type: Number,
    status: Number,
    calc: Object,
    matrix: Object,
    secondMatrix: Object,
    image: {
      type: String,
      default: 'incremental_triangle'
    },
    helpString: String,
    isOutdatedReport: false,
  },
  inject: ['children_status', 'state', 'canModify', 'lockStatus'],
  emits: ['downloadMatrix', 'reloadMatrixCells', 'recalculate', 'process', 'onFieldChange'],
  components: {
    Matrix
  },
  computed: {
    isResult() {
      return Number(this.$route.name.substr(-3)) === 199
    },
    canModifyMatrix() {
      if (this.type === 199 && this.matrix.order > 1) {
        const status = this.children_status[this.matrix.associated_report_type]
        return this.lockStatus?.locked === true &&
            ([101, 102, 103].includes(status) && this.hasPerm('draft')) ||
            ([201, 202, 203].includes(status) && this.hasPerm('pending_approval'))
      }
      return this.canModify
    }
  },
  methods: {
    hasPerm(permission) {
      return window.drfUser.permissions.includes('api.' + permission)
    },
    getStyle(calc_order, matrix_order, show_on_true) {
      const key = this.type + '_' + (calc_order || '') + '_' + matrix_order
      return !!this.state[key] === show_on_true ? '' : 'display: none'
    },
    toggle(calc_order, matrix_order) {
      const key = this.type + '_' + (calc_order || '') + '_' + matrix_order
      this.state[key] = !this.state[key]
    },
    emitReloadMatrixCells(ids) {
      this.$emit('reloadMatrixCells', ids)
    },
    emitDownloadMatrix() {
      let ids = [this.matrix.id]
      if (this.secondMatrix) {
        ids.push(this.secondMatrix.id)
      }
      this.$emit('downloadMatrix', {'model': 'Matrix', 'ids': ids})
    },
    emitRecalculate() {
      this.$emit('recalculate', null)
    },
    emitFieldChange(field, year) {
      this.$emit('onFieldChange', field, year)
    },
    emitProcess(action) {
      if (this.matrix.order === 1) {
        this.$emit('process', {'action': action})
      } else {
        this.$emit('process', {'action': action, 'matrix_id': this.matrix.id})
      }
    }
  }
}
</script>

<style scoped>
.buttons {
  display: flex;
  align-items: start;
}

.buttons div {
  padding: 0.5rem;
  border-bottom: 2px solid #3ebaff;
  margin-bottom: 1rem;
}

.buttons a {
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: #3ebaff;
  white-space: nowrap;
  font-size: 0.95rem;
}

.buttons a:after {
  content: url('@/assets/img/indicator_active.svg');
}

.buttons div.disabled {
  border-bottom: 1px solid #6b6f77;
}

.buttons div.disabled a {
  color: #6b6f77;
}

.buttons div.disabled a:after {
  content: url('@/assets/img/indicator_no_active.svg');
}

.open-link {
  margin: 0 -1rem;
  border-top: 1px solid #c0c0c0;
  padding: 1rem 1rem 0 1rem;
}

.collapse-link {
  margin: 0 -1rem;
  padding: calc(1rem + 1px) 1rem 1rem 1rem;
}

.open-link > a, .collapse-link > a {
  cursor: pointer;
}

.open-link > a > span, .collapse-link > a > span {
  color: #3ebaff;
  padding-left: 1rem;
}

.open-link > a > img {
  width: 1rem;
}

.collapse-link > a > img {
  width: 1rem;
  transform: rotate(180deg);
}

.matrix-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  color: #292d32;
  font-size: 1.1rem;
}

.matrix-header > img {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.top-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 20rem - 4px);
}

.top-right {
  display: flex;
  align-items: center;
}

.top-right-item {
  display: flex;
  align-items: center;
  border-left: 1px solid #c0c0c0;
  padding: 0.3rem 1rem;
  margin-bottom: 0.5rem;
}

.top-right-item:first-child {
  border-left: none;
}

.top-right-item > a {
  padding-left: 0.5rem;
  cursor: pointer;
  color: #3ebaff;
}

.is-outdated {
  color: red !important;
}

.coefficients {
  margin: -0.5rem 0 0.5rem 2rem;
  font-size: 0.8rem;
}

.second {
  margin-top: 1.5rem;
}

.filter {
  display: flex;
  gap: 2rem;
  margin: 1rem 2rem 1rem 2rem;
}

.filter-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 0.3rem;
}

.filter-item select{
  width: 12rem;
  min-width: min-content;
}

.filter-item-title {
  color: #0074B5;
}
</style>
