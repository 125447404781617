<template>
  <div class="tree">
    <LeftTypeSelectorItem :types="[]" :title="'Свод по расчетам'" :link="'/dashboard'"/>
    <LeftTypeSelectorItem :type="type" :types="[101, 106, 107, 108, 109, 110, 111, 112, 113]" :can-modify="canModify"
                          :title="'Наилучшие оценки'" :link="'/reports/101'" :items="items" :versions="versions"
                          @scroll-to-calc="scrollToCalc" @add-calc="addCalc"/>
    <LeftTypeSelectorItem :type="type" :types="[102, 114]" :can-modify="canModify" :title="'Рисковые поправки'"
                          :link="'/reports/102'" :items="items" :versions="versions" @scroll-to-calc="scrollToCalc"
                          @add-calc="addCalc"/>
    <LeftTypeSelectorItem :type="type" :types="[199]" :can-modify="canModify" :title="'Обзор результата'"
                          :link="'/reports/199'" :items="items" @scroll-to-calc="scrollToCalc" @add-calc="addCalc"/>
    <LeftTypeSelectorItem :type="type" :types="[103, 104, 116, 117, 127, 128, 129, 130, 118, 119, 131, 132]" :can-modify="canModify"
                          :title="'Денежные потоки'" :link="'/reports/103'" :items="items"
                          @scroll-to-calc="scrollToCalc" @add-calc="addCalc"/>
    <LeftTypeSelectorItem :type="type" :types="[115, 120, 121, 122, 123, 124, 125, 126]" :can-modify="canModify"
                          :title="'Перестрахование'" :link="'/reports/115'" :items="items" :versions="versions"
                          @scroll-to-calc="scrollToCalc" @add-calc="addCalc"/>
  </div>
</template>

<script>
import LeftTypeSelectorItem from "@/components/LeftTypeSelectorItem";

export default {
  name: 'LeftTypeSelector',
  components: {
    LeftTypeSelectorItem
  },
  props: {
    type: Number,
    items: Array,
    canModify: Boolean,
    versions: Array,
  },
  emits: ['scrollToCalc', 'addCalc'],
  methods: {
    scrollToCalc(id) {
      this.$emit('scrollToCalc', id)
    },
    addCalc(data) {
      this.$emit('addCalc', data)
    }
  }
}
</script>

<style scoped>
.tree {
  padding-top: 1rem;
  box-shadow: 4px 0 4px rgba(0, 0, 0, 0.15);
  margin-right: 5px;
  width: 15rem;
  background-color: #ffffff;
  position: fixed;
  height: 100%;
  z-index: 500;
}
</style>
