<template>
  <div class="container">
    <div class="calc-name">
      {{ calc.name }}
    </div>
    <div class="block">
      <div class="top-buttons">
        <div class="top-left">
          <img src="@/assets/img/incremental_triangle.svg" alt="">
          <router-link v-if="type === 101" :to="`/calculations/${calc.id}`" target="_blank">
            {{ calc.name }}
          </router-link>
          <span v-else>
            {{ calc.name }}
          </span>
        </div>
        <div class="top-right">
          <div v-if="![101, 120, 121, 122, 123, 124, 125, 126].includes(type) && (![104, 127, 131, 119, 118, 132].includes(type) || calc.associated_report_type) && canModify"
               class="top-right-item">
            <a class="top-button" :class="calc.is_outdated ? 'is-outdated' : ''" @click="emitRecalculate">Рассчитать</a>
          </div>
          <div v-if="[101, 106, 107, 108, 109, 110, 111, 112, 113].includes(type) && !calc.is_global_template"
               class="top-right-item">
            <img src="@/assets/img/copy.svg" alt="">
            <a class="top-button" @click="setGlobal">Сохранить как шаблон</a>
          </div>
          <div v-if="calc.is_cloneable && canModify" class="top-right-item">
            <img src="@/assets/img/copy.svg" alt="">
            <a class="top-button" @click="copyCalc" test-id="copy_report">Копировать расчет</a>
          </div>
          <div class="top-right-item">
            <img src="@/assets/img/download.svg" alt="">
            <a class="top-button" @click="downloadCalc" test-id="download_report">Скачать</a>
          </div>
          <div v-if="calc.order > 1 && !calc.is_global_template && calc.is_cloneable && canModify"
               class="top-right-item">
            <img src="@/assets/img/delete.svg" alt="">
            <a class="top-button" @click="$emit('deleteCalc', calc.id)" test-id="delete_report">Удалить</a>
          </div>
        </div>
      </div>

      <div
          v-if="[101, 105, 106, 107, 108, 109, 110, 111, 112, 113, 115, 120, 121, 122, 123, 124, 125, 126].includes(type)"
          class="status-line">
        <div class="status-item">
          <div class="pax-th status-item-name">
            Период треугольника
          </div>
          {{ calc.period_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Количество периодов
          </div>
          {{ calc.dimension }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            База треугольника
          </div>
          {{ calc.base_name }}
        </div>
        <div v-if="type !== 105" class="status-item">
          <div class="pax-th status-item-name">
            Метод расчета
          </div>
          {{ calc.method_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Тип убытков
          </div>
          {{ calc.type_name }}
        </div>
        <div class="status-item" v-if="type === 115">
          <div class="pax-th status-item-name">
            Тип треугольника
          </div>
          Доля перестраховщиков
        </div>
        <div v-if="type !== 105" class="status-item">
          <div class="pax-th status-item-name">
            Версия данных
          </div>
          {{ calc.version }}
        </div>
      </div>

      <div v-else-if="[104, 116, 127, 129].includes(type)" class="status-line">
      <div class="status-item">
         <div class="pax-th status-item-name">
            Версия данных
         </div>
          {{ calc.version }}
        </div>
      </div>

      <div v-if="type === 102" class="status-line">
        <div class="status-item">
          <div class="pax-th status-item-name">
            Треугольник для расчета из метода
          </div>
          {{ calc.calc_for_triangle_name }}
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Уровень доверия, %
          </div>
          <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.level * 100)"
                 @change="changeLevel($event.target)">
          <template v-else>
            {{ $numberFormat.format(calc.level * 100) }}
          </template>
        </div>
        <div class="status-item">
          <div class="pax-th status-item-name">
            Количество симуляций
          </div>
          <input v-if="canModify" type="text" class="sim-count" :value="$intFormat.format(calc.simulation_count)"
                 @change="changeSimCount($event.target)">
          <template v-else>
            {{ $intFormat.format(calc.simulation_count) }}
          </template>
        </div>
      </div>

      <template v-if="type === 114">
        <div class="status-line">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Метод расчета
            </div>
            {{ calc.method_name }}
          </div>
        </div>
        <div class="status-line">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Треугольник развития убытков
            </div>
            {{ calc.calc_for_triangle_name }}
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Квантиль, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.quantile)"
                   @change="changeQuantile($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.quantile) }}
            </template>
          </div>
          <div class="status-item" v-if="calc.method === 1401 || calc.method === 1403 ">
            <div class="pax-th status-item-name">
              Метод расчета
            </div>
            <select v-if="canModify" @change="changeCalcField('quantile_type', $event.target.value)">
              <option value="1" :selected="calc.quantile_type === 1">Аддитивный</option>
              <option value="2" :selected="calc.quantile_type === 2">Мультипликативный</option>
            </select>
            <template v-else>
              {{ calc.quantile_type }}
            </template>
          </div>
        </div>
      </template>

      <div class="status-line">
        <div v-if="calc.method === 102 && type !== 115 || calc.method === 102 && type !== 120" class="status-item">
          <div class="pax-th status-item-name">
            Метод
          </div>
          <select v-if="canModify" @change="changeCalcField('bf_method', $event.target.value)">
            <option value="1" :selected="calc.bf_method === 1">Метод Борнхьюттера-Фергюссона</option>
            <option value="2" :selected="calc.bf_method === 2">Метод Naive loss ratio</option>
          </select>
          <template v-else>
            {{ calc.bf_method_name }}
          </template>
        </div>

        <div v-if="[101, 112].includes(type) && [102, 103, 104].includes(calc.method)" class="status-item">
          <div class="pax-th status-item-name">
            Обнуляется отрицательный РПНУ
          </div>
          <select v-if="canModify" @change="changeCalcField('negative_to_zero', $event.target.value)">
            <option value="1" :selected="calc.negative_to_zero">Да</option>
            <option value="0" :selected="!calc.negative_to_zero">Нет</option>
          </select>
          <template v-else>
            {{ calc.negative_to_zero ? 'Да' : 'Нет' }}
          </template>
        </div>

        <div v-if="type === 120 && calc.method === 102" class="status-item">
          <div class="pax-th status-item-name">
            Обнуляется отрицательная доля в РПНУ
          </div>
          <select v-if="canModify" @change="changeCalcField('negative_to_zero', $event.target.value)">
            <option value="1" :selected="calc.negative_to_zero">Обнуляется отрицательная доля в РПНУ</option>
            <option value="0" :selected="!calc.negative_to_zero">Не обнуляется отрицательная доля РПНУ</option>
          </select>
          <template v-else>
            {{ calc.negative_to_zero ? 'Обнуляется отрицательная доля в РПНУ' : 'Не обнуляется отрицательная доля РПНУ' }}
          </template>
        </div>

        <div
            v-if="[103, 117, 128, 130].includes(type) && calc.associated_report_type != 128 && calc.associated_report_type != 103 && calc.associated_report_type != 117  && calc.associated_report_type != 130"
            class="status-item">
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 113">
            Треугольник развития перв.треб. из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 112">
            Треугольник развития нул.треб. из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 106">
            Треугольник развития РРУУ (прям.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 108">
            Треугольник развития РРУУ (судебн.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 107">
            Треугольник развития РРУУ (проч.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 111">
            Треугольник развития РРУУ (косв.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 110">
            Треугольник развития регрессов из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 109">
            Треугольник развития ГОТС из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 120">
            Треугольник развития возмещений убытков из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 121">
            Треугольник развития доли в РРУУ (прям.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 122">
            Треугольник развития доли в РРУУ (проч.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 123">
            Треугольник развития доли в РРУУ (суд.) из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 125">
            Треугольник развития доли в ГОТС из расчета
          </div>
          <div class="pax-th status-item-name" v-else-if="calc.associated_report_type === 126">
            Треугольник развития доли в регрессах из расчета
          </div>
          <div v-else class="pax-th status-item-name">
            Треугольник развития убытков из расчета
          </div>
          <select v-if="canModify" @change="changeCalcField('calc_for_triangle_id', $event.target.value)">
            <option v-for="estimateCalculation in calc.estimates_calculations" :value="estimateCalculation.id"
                    :key="estimateCalculation.id"
                    :selected="estimateCalculation.id === calc.calc_for_triangle_id">
              {{ estimateCalculation.name }}
            </option>
          </select>
          <template v-else>
            {{ calc.calc_for_triangle_name }}
          </template>
        </div>

        <div
            v-if="[101, 106, 107, 108, 111, 110, 109, 121, 122, 123, 125, 126, 112, 113,120].includes(calc.associated_report_type) && [103, 117, 128, 130].includes(type)"
            class="status-item">
            <div class="pax-th status-item-name" v-if="calc.associated_report_type === 101">
            Используются факторы развития РУ по моменту
            </div>
            <div class="pax-th status-item-name" v-if="calc.associated_report_type === 120">
            Используются факторы развития доли в РУ по моменту
            </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 106">
            Используются факторы развития РУУ (прям.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 108">
            Используются факторы развития РУУ (судебн.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 107">
            Используются факторы развития РУУ (проч.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 111">
            Факторы развития РУУ (косв.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 110">
            Факторы развития регрессов по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 109">
            Факторы развития ГОТС по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 121">
            Используются факторы развития доли в РУУ (прям.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 123">
            Используются факторы развития доли в РУУ (судебн.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 122">
            Используются факторы развития доли в РУУ (проч.) по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 126">
            Факторы развития доли в регрессах по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 125">
            Факторы развития доли в ГОТС по моменту
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 112">
            Факторы развития нулевых требований
          </div>
          <div class="pax-th status-item-name" v-if="calc.associated_report_type === 113">
            Факторы развития РСА
          </div>
          <select v-if="canModify" @change="changeCalcField('factors_moment', $event.target.value)">
            <option :selected="calc.factors_moment === 1" value="1">Оплаты убытков</option>
            <option :selected="calc.factors_moment === 2" v-if="[101, 106, 107, 108, 110, 109, 121, 122, 123, 125, 126, 112, 120, 113].includes(calc.associated_report_type)" value="2">Ручной ввод</option>
            <option :selected="calc.factors_moment === 3" v-if="calc.associated_report_type === 109 || calc.associated_report_type === 125" value="3">Возн.
              прав на ГОТС
            </option>
            <option :selected="calc.factors_moment === 4" v-if="calc.associated_report_type === 110 " value="4">Возн.
              прав на регрессы
            </option>


            <option :selected="calc.factors_moment === 5"
                v-if="calc.associated_report_type === 106 || calc.associated_report_type === 107
                && checkPRYAM(report.calculation_set).includes(106) " value="5" >
                    Понесения РУУ (прям.)
            </option>

            <option :selected="calc.factors_moment === 6" v-if="calc.associated_report_type === 107 " value="6">Понесения
              РУУ (проч.)
            </option>
            <option :selected="calc.factors_moment === 7" v-if="calc.associated_report_type === 108 " value="7">Понесения
              РУУ (судебн.)
            </option>
          </select>
        </div>



        <div v-if="type === 105" class="status-item">
          <div class="pax-th status-item-name">
            Версия данных
          </div>
          <select v-if="canModify" @change="changeCalcField('calc_for_triangle_id', $event.target.value)">
            <option v-for="v in report.ru_versions" :value="v.id" :key="v.id"
                    :selected="v.id === calc.calc_for_triangle_id">
              {{ v.version }}
            </option>
          </select>
          <template v-else>
            {{ calc.calc_for_triangle_name }}
          </template>
        </div>

        <div
            v-if="[101, 106, 107, 108, 109, 110, 111, 112, 113, 120, 121, 122, 123, 125, 126].includes(calc.associated_report_type) && [103, 117, 128, 130].includes(type)"
            class="status-item">
          <div class="pax-th status-item-name">
            Драйвер для аллокации по когортам
          </div>
          <select @change="changeCalcField('dp_allocation_driver', $event.target.value)">
            <option value="1" :selected="calc.dp_allocation_driver === 1">Заявленные убытки</option>
            <option value="2" :selected="calc.dp_allocation_driver === 2">Понесённые убытки</option>
            <option value="3" :selected="calc.dp_allocation_driver === 3">
              Оплаченные убытки
            </option>
            <option value="4" :selected="calc.dp_allocation_driver === 4" v-if="calc.associated_report_type === 107">
              Оплаченные РУУ (проч.)
            </option>
            <option value="4" :selected="calc.dp_allocation_driver === 4" v-if="calc.associated_report_type === 106">
              Оплаченные РУУ (прям.)
            </option>
            <option value="4" :selected="calc.dp_allocation_driver === 4" v-if="calc.associated_report_type === 108">
              Оплаченные РУУ (судебн.)
            </option>
          </select>
        </div>

        <div v-if="[117, 130].includes(type) && calc.associated_report_type !== 117 && calc.associated_report_type !== 130" class="status-item">
          <div class="pax-th status-item-name">
            Драйвер для разбивки РПНУ по месяцам возникновения
          </div>
          <select @change="changeCalcField('dp_rpnu_driver', $event.target.value)">
            <option value="3" :selected="calc.dp_rpnu_driver === 3">Пропорционально выручке</option>
            <option value="1" :selected="calc.dp_rpnu_driver === 1">Пропорционально времени</option>
          </select>
        </div>

        <div v-if="type == 116 && calc.associated_report_type === null || type == 129 && calc.associated_report_type === null" class="status-item">
          <div class="pax-th status-item-name">
            Когорта
          </div>
          <select @change="changeCalcField('selected_cohort', $event.target.value)">
            <option v-for="year, index in calc.avaliable_cohorts" :value="year" :key="index"
                    :selected="year === calc.selected_cohort">
              {{ year }}
            </option>
          </select>
        </div>

        <div
            v-if="type == 116 && calc.associated_report_type === null || type == 129 && calc.associated_report_type === null"
            class="status-item">
          <div class="pax-th status-item-name">
            Тип бизнеса
          </div>
          <select @change="changeCalcField('selected_business_type', $event.target.value)">
            <option value="1" :selected="calc.selected_business_type === 1">Действующий</option>
            <option value="2" :selected="calc.selected_business_type === 2">Новый</option>
          </select>
        </div>

        <div v-if="[117, 130].includes(type) && calc.associated_report_type !== 117 && calc.associated_report_type !== 130" class="status-item">
          <div class="pax-th status-item-name">
            Переход к месячному расчету высвобождения резерва
          </div>
          <select @change="changeCalcField('dp_reserve_release', $event.target.value)">
            <option value="1" :selected="calc.dp_allocation_driver === 1">Пропорционально времени</option>
            <option value="2" :selected="calc.dp_allocation_driver === 2">Линейная интерполяция</option>
            <option value="3" :selected="calc.dp_allocation_driver === 3">Ручной ввод</option>
          </select>
        </div>

        <div v-if="[106, 107, 108, 109, 110, 111].includes(type)" class="status-item">
          <div v-if="[101, 102, 103, 104].includes(calc.method)" class="pax-th status-item-name">
            Версия данных для прямых выплат
          </div>
          <div v-else-if="calc.method === 601" class="pax-th status-item-name">
            Версия данных для выплат и РЗНУ
          </div>
          <div v-else-if="calc.method === 602" class="pax-th status-item-name">
            Версия данных для количества убытков
          </div>
          <select v-if="canModify" @change="changeCalcField('calc_for_factors_id', $event.target.value)">

          <template v-if="[101, 102, 103, 104, 601].includes(calc.method)" >
            <option v-for="v in report.ru_names" :key="v.id" :value="v.id"
                    :selected="v.id === calc.calc_for_factors_id" >
              {{ v.version }}
            </option>
          </template>

          <template v-else-if="calc.method === 602" >
            <option v-for="fa_name in report.ru_fa_names" :key="fa_name.id" :value="fa_name.id"
                    :selected="fa_name.id === calc.calc_for_factors_id">
              {{ fa_name.version }}
            </option>
          </template>

          </select>
          <template v-else>
            {{ calc.calc_for_factors_name }}
          </template>
        </div>

        <template v-if="calc.method === 601 && ![115, 120, 121, 122, 123, 124, 125, 126].includes(type)">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Обнуляется отрицательный РПНУ
            </div>
            <select v-if="canModify" @change="changeCalcField('negative_to_zero', $event.target.value)">
              <option value="1" :selected="calc.negative_to_zero">Да</option>
              <option value="0" :selected="!calc.negative_to_zero">Нет</option>
            </select>
            <template v-else>
              {{ calc.negative_to_zero ? 'Да' : 'Нет' }}
            </template>
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Доля РУУ в РЗНУ, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.ocr_share)"
                   @change="changeOcrShare($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.ocr_share) }}
            </template>
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Доля РУУ в РПНУ, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.ibnr_share)"
                   @change="changeIbnrShare($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.ibnr_share) }}
            </template>
          </div>
        </template>

        <template v-if="calc.method === 601 && [121, 122, 123, 124, 125, 126].includes(type)">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Обнуляется отрицательный РПНУ
            </div>
            <select v-if="canModify" @change="changeCalcField('negative_to_zero', $event.target.value)">
              <option value="1" :selected="calc.negative_to_zero">Да</option>
              <option value="0" :selected="!calc.negative_to_zero">Нет</option>
            </select>
            <template v-else>
              {{ calc.negative_to_zero ? 'Да' : 'Нет' }}
            </template>
          </div>
          <div class="status-item">
            <div v-if='type === 121' class="pax-th status-item-name">
              Доля прямых РУУ в РУ, %
            </div>
            <div v-else-if='type === 122' class="pax-th status-item-name">
              Доля прочих РУУ в РУ, %
            </div>
            <div v-else-if='type === 123' class="pax-th status-item-name">
              Доля судебных РУУ в РУ, %
            </div>
            <div v-else-if='type === 125' class="pax-th status-item-name">
              Доля ГОТС в РУ, %
            </div>
            <div v-else-if='type === 126' class="pax-th status-item-name">
              Доля регрессов в РУ, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.loss_share)"
                   @change="changeLossShare($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.loss_share) }}
            </template>
          </div>
        </template>

        <template v-if="calc.method === 601 && type === 120">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Обнуляется отрицательная доля перестраховщиков в РПНУ
            </div>
            <select v-if="canModify" @change="changeCalcField('negative_to_zero', $event.target.value)">
              <option value="1" :selected="calc.negative_to_zero">Да</option>
              <option value="0" :selected="!calc.negative_to_zero">Нет</option>
            </select>
            <template v-else>
              {{ calc.negative_to_zero ? 'Да' : 'Нет' }}
            </template>
          </div>

          <div class="status-item">
            <div class="pax-th status-item-name">
              Доли перестраховщиков в РЗНУ, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.ocr_share)"
                   @change="changeOcrShare($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.ocr_share) }}
            </template>
          </div>

          <div class="status-item">
            <div class="pax-th status-item-name">
              Доли перестраховщиков в РПНУ, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.ibnr_share)"
                   @change="changeIbnrShare($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.ibnr_share) }}
            </template>
          </div>
        </template>

        <template v-if="calc.method === 602">
          <div class="status-item">
            <div class="pax-th status-item-name">
              Сумма РУУ на один убыток
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.simple_claim)"
                   @change="changeSimpleClaim($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.simple_claim) }}
            </template>
          </div>
          <div class="status-item">
            <div class="pax-th status-item-name">
              Доля РУУ на урегулирование после заявления, %
            </div>
            <input v-if="canModify" type="text" class="level" :value="$numberFormat.format(calc.ibnr_share)"
                   @change="changeIbnrShare($event.target)">
            <template v-else>
              {{ $numberFormat.format(calc.ibnr_share) }}
            </template>
          </div>
        </template>
      </div>

      <div v-if="calc.total !== null" class="total">
        Итого: {{ $numberFormat.format(calc.total / divider) }}
      </div>

      <div class="open-link">
        <a @click="changeState(true)" class="open-all">
          <img src="@/assets/img/incremental_more_detailed.svg" alt="" test-id="expand_all">
          <span>развернуть все</span>
        </a>
        <a @click="changeState(false)" class="collapse-all">
          <img src="@/assets/img/incremental_more_detailed.svg" alt="">
          <span>свернуть все</span>
        </a>
      </div>
    </div>

    <template v-if="type === 101">
      <div v-if="calc.method === 101" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 102" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [8], [9], [10]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 103" class="block"
           v-for="pair in [[0], [1], [11], [12], [13], [14, 15], [16], [17], [18], [19], [20], [21], [22, 23], [24], [25], [26], [27], [28]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 104" class="block"
           v-for="pair in [[30]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 102">
      <div v-for="pair in [[0], [1, 2]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 103">
      <div v-if="calc.associated_report_type === 113"
           v-for="pair in [ [calc.matrix_set.length - 4], [0], [1], [2], [3], [4], [5], [6], [24], [7], [8], [9], [10], [11], [12], [13], [14], [15, 16]]"
           class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.associated_report_type === 101"
           v-for="pair in [[[calc.matrix_set.length - 6]], [0], [1], [2], [3], [4], [5], [6], [7, 8]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.associated_report_type === 111"
           v-for="pair in [[0], [1], [2], [3], [4], [5], [6], [7, 8]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="[106, 107, 108, 109, 110].includes(calc.associated_report_type)"
           v-for="pair in [[[calc.matrix_set.length - 6]], [0], [1], [2], [3], [4], [5], [6], [7, 8]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.associated_report_type === 112"
           v-for="pair in [[[calc.matrix_set.length - 3]], [0], [1], [2], [3], [4], [5], [6], [7, 8]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.associated_report_type === 103" v-for="matrix in calc.matrix_set.slice(0, -1)" :key="matrix.order"
           class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 104">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div v-if="(matrix.order >= 1 && matrix.order != 2) || !calc.associated_report_type" class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 116">
        <template v-for="matrix in calc.matrix_set" :key="matrix.order">
            <div v-if="matrix.order == 1 && !calc.associated_report_type"
                class="block">
              <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                            @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                            @onFieldChange="changeCalcField" @on="changeCalcField"/>
            </div>

            <div v-else-if="[1, 2, 6, 7, 8, 9, 10, 11].includes(matrix.order) &&
            [101].includes(calc.associated_report_type)" class="block">
              <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                            @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                            @onFieldChange="changeCalcField" @on="changeCalcField"/>
            </div>
            <div v-else-if="[1, 2, 6, 7, 8, 9].includes(matrix.order) &&
            [106, 107, 108, 109, 110, 111].includes(calc.associated_report_type)" class="block">
              <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                            @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                            @onFieldChange="changeCalcField" @on="changeCalcField"/>
            </div>
            <div v-else-if="[1, 2, 6, 7, 8, 10, 11, 12].includes(matrix.order) &&
            [112, 113].includes(calc.associated_report_type)" class="block">
              <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                            @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                            @onFieldChange="changeCalcField" @on="changeCalcField"/>
            </div>

      </template>
    </template>

    <template v-else-if="type === 105">
      <div v-for="pair in [[0], [1], [2], [3], [4, 5], [6], [7], [8], [9]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="[106, 107, 108].includes(type)">
      <div v-if="calc.method === 101" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 102" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [8], [9], [10]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 601" class="block"
           v-for="pair in [[11]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 602" class="block"
           v-for="pair in [[12]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="[109, 110, 112].includes(type)">
      <div v-if="calc.method === 101" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 102" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [8], [9], [10]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 104" class="block"
           v-for="pair in [[11]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 111">
      <div v-if="calc.method === 601" class="block"
           v-for="pair in [[0]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 602" class="block"
           v-for="pair in [[1]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 117">
      <template v-if="calc.associated_report_type === 117" v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>

      <template v-else-if="calc.associated_report_type === 112" v-for="pair in [ [8], [10], [11], [12], [13], [14], [15], [16, 17], [18, 19]]">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                        :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                        @download-matrix="downloadMatrix"/>
        </div>
      </template>

      <template v-else-if="calc.associated_report_type === 113" v-for="pair in [ [6], [7], [8], [9], [10], [11], [12], [13], [14], [15],  [16], [17], [18], [19], [20, 21], [22, 23]]">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                        :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                        @download-matrix="downloadMatrix"/>
        </div>
      </template>

      <template v-else v-for="pair in [[14], [15], [16], [17], [18], [19], [20], [21, 22], [23, 24]]">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                        :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                        @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 130">
      <template v-if="calc.associated_report_type === 130" v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>

      <template v-else v-for="pair in [[15], [16], [17], [14], [18], [19], [20], [21], [22, 23], [25, 24]]">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                        :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                        @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 113">
      <div v-if="calc.method === 101" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7], [8], [9], [10], [11, 12], [13], [14], [15], [16]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 102" class="block"
           v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [17], [18], [19], [8], [9], [10], [11, 12], [13], [14], [20], [21], [22], [23]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 104" class="block"
           v-for="pair in [[24]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 114">
      <div v-if="calc.method === 1401" class="block"
           v-for="pair in [[1], [2], [4]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 1403" class="block"
           v-for="pair in [[1], [3], [4]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 1402" class="block"
           v-for="pair in [ [5], [7, 8], [4]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.method === 1404" class="block"
           v-for="pair in [[6], [7, 8], [4]]">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 115">
      <div v-if="calc.order === 1" v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.order === 2" v-for="pair in [[0], [1], [2], [3, 4], [5], [6], [7], [8], [9]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-else-if="calc.order === 3" v-for="pair in [[0], [1, 2]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 120">
      <div v-if="calc.method === 101" v-for="pair in [[4], [5], [6], [7, 8], [9], [10], [11]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-if="calc.method === 102" v-for="pair in [[4], [5], [6], [7, 8], [9], [10], [11], [12], [13]]"
           class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      <div v-if="calc.method === 601" v-for="pair in [[5]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]" @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="[121, 122, 123, 124, 125, 126].includes(type)">
      <div v-if="calc.method === 601" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[5]"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 127">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div v-if="matrix.order >= 1 || !calc.associated_report_type" class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 128">
      <div v-if="calc.associated_report_type != 128"
           v-for="pair in [[15], [0], [1], [2], [3], [4], [5], [6], [7, 8]]" class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="calc.matrix_set[pair[0]]"
                      :second-matrix="calc.matrix_set[pair[1]]"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
      
      <div v-else v-for="matrix in calc.matrix_set" :key="matrix.order"
           class="block">
        <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                      @reload-matrix-cells="emitReloadMatrixCells"
                      @download-matrix="downloadMatrix"/>
      </div>
    </template>

    <template v-else-if="type === 129">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div
            v-if="matrix.order >= 1 && matrix.order <= 8 && calc.associated_report_type == 120" class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                        @onFieldChange="changeCalcField" @on="changeCalcField"/>
        </div>
        <div
            v-else-if="matrix.order <= 6 && [121, 122, 123, 125, 126].includes(calc.associated_report_type)" class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                        @onFieldChange="changeCalcField" @on="changeCalcField"/>
        </div>
        <div
            v-else-if="matrix.order == 2 && !calc.associated_report_type" class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"
                        @onFieldChange="changeCalcField" @on="changeCalcField"/>
        </div>

      </template>
    </template>

    <template v-else-if="type === 119">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 118">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 131">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>

    <template v-else-if="type === 132">
      <template v-for="matrix in calc.matrix_set" :key="matrix.order">
        <div class="block">
          <MatrixHolder :type="type" :status="status" :calc="calc" :matrix="matrix"
                        @reload-matrix-cells="emitReloadMatrixCells" @download-matrix="downloadMatrix"/>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import MatrixHolder from "@/components/Report/MatrixHolder";

export default {
  props: {
    report: Object,
    calc: Object,
  },
  inject: ['state', 'divider', 'canModify'],
  computed: {
    type() {
      return this.report.type
    },
    status() {
      return this.report.status
    }
  },
  components: {
    MatrixHolder
  },
  emits: ['copyCalc', 'deleteCalc', 'reloadMatrixCells', 'updateCalc', 'recalculate', 'downloadMatrix', 'setGlobal'],
  methods: {
    changeState(state) {
      for (let i = 0; i < this.calc.matrix_set.length; i++) {
        const key = this.type + '_' + this.calc.order + '_' + this.calc.matrix_set[i].order
        this.state[key] = state
      }
    },
    checkPRYAM(report_set){
        let values = []

        for (let i = 0; i < report_set.length; i++) {
          values.push(report_set[i].associated_report_type);
        }
        return values
    },
    getValue(input, prevValue) {
      let value = input.value.replaceAll(',', '.').trim()
      if (!isNaN(parseFloat(value)) && !isNaN(value - 0)) {
        value = parseFloat(value)
        if (value !== parseFloat(prevValue)) {
          return value
        }
      }
      return null
    },
    changeLevel(input) {
      let value = this.getValue(input, this.calc.level * 100)
      if (value === null) {
        input.value = this.$numberFormat.format(this.calc.level * 100)
      } else {
        this.calc.level = value / 100
        input.value = this.$numberFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'level', 'value': value / 100})
      }
    },
    changeSimCount(input) {
      let value = this.getValue(input, this.calc.simulation_count)
      if (value === null) {
        input.value = this.$intFormat.format(this.calc.simulation_count)
      } else {
        value = Math.round(value)
        this.calc.simulation_count = value
        input.value = this.$intFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'simulation_count', 'value': value})
      }
    },
    changeOcrShare(input) {
      let value = this.getValue(input, this.calc.ocr_share)
      if (value === null) {
        input.value = this.$numberFormat.format(this.calc.ocr_share)
      } else {
        this.calc.ocr_share = value
        input.value = this.$numberFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'ocr_share', 'value': value})
      }
    },
    changeLossShare(input) {
      let value = this.getValue(input, this.calc.loss_share)
      if (value === null) {
        input.value = this.$numberFormat.format(this.calc.loss_share)
      } else {
        this.calc.loss_share = value
        input.value = this.$numberFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'loss_share', 'value': value})
      }
    },
    changeIbnrShare(input) {
      let value = this.getValue(input, this.calc.ibnr_share)
      if (value === null) {
        input.value = this.$numberFormat.format(this.calc.ibnr_share)
      } else {
        this.calc.ibnr_share = value
        input.value = this.$numberFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'ibnr_share', 'value': value})
      }
    },
    changeSimpleClaim(input) {
      let value = this.getValue(input, this.calc.simple_claim)
      if (value === null) {
        input.value = this.$numberFormat.format(this.calc.simple_claim)
      } else {
        this.calc.simple_claim = value
        input.value = this.$numberFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'simple_claim', 'value': value})
      }
    },
    changeQuantile(input) {
      let value = this.getValue(input, this.calc.quantile)
      if (value === null) {
        input.value = this.$numberFormat.format(this.calc.quantile)
      } else {
        this.calc.quantile = value
        input.value = this.$numberFormat.format(value)
        this.$emit('updateCalc', {'id': this.calc.id, 'name': 'quantile', 'value': value})
      }
    },
    changeCalcField(name, value) {
      this.$emit('updateCalc', {'id': this.calc.id, 'name': name, 'value': value})
    },
    emitReloadMatrixCells(ids) {
      this.$emit('reloadMatrixCells', ids)
    },
    copyCalc() {
      this.$emit('copyCalc', {'id': this.calc.id, 'report_id': this.calc.report_id})
    },
    emitRecalculate() {
      this.$emit('recalculate', this.calc.id)
    },
    downloadMatrix(ids) {
      this.$emit('downloadMatrix', ids)
    },
    downloadCalc() {
      this.downloadMatrix({'model': 'Calculation', 'ids': [this.calc.id]})
    },
    setGlobal() {
      this.$emit('setGlobal', this.calc.id)
    }
  }
}


</script>

<style scoped>
.calc-name {
  font-size: 1.1rem;
  margin: 0 0 0.3rem 1rem;
  color: #3ebaff;
}

.top-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 20rem - 4px);
}

.top-left {
  display: flex;
  align-items: center;
}

.top-left > img {
  width: 1.5rem;
  margin-right: 0.5rem;
}

.top-left > a, .top-left > span {
  color: #292d32;
  font-size: 1.1rem;
  text-decoration: none;
}

.top-right {
  display: flex;
  align-items: center;
}

.top-right-item {
  display: flex;
  align-items: center;
  border-left: 1px solid #c0c0c0;
  padding: 0.5rem 1rem;
}

.top-right-item:first-child {
  border-left: none;
}

.top-right-item > a {
  padding-left: 0.5rem;
  cursor: pointer;
  color: #3ebaff;
}

.total {
  margin: 1rem 0 1rem 2rem;
}

.block {
  background-color: #ffffff;
  box-shadow: 5px 5px 5px #e0e0e0;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #c0c0c0;
  border-radius: 5px;

}

.open-link {
  margin: 0 -1rem;
  border-top: 1px solid #c0c0c0;
  padding: 1rem 1rem 0 1rem;
}

.open-link > a {
  cursor: pointer;
}

.open-link > a > span {
  color: #3ebaff;
  padding-left: 1rem;
}

.open-link > a > img {
  width: 1rem;
}

.open-all {
  margin-right: 2rem;
}

.collapse-all > img {
  transform: rotate(180deg);
}

.status-line {
  display: flex;
  margin-left: 2rem;
}

.status-item {
  margin: 1rem 2rem 1rem 0;
}

.status-item-name {
  margin-bottom: 0.3rem;
}

.status-item > select {
  width: 12rem;
  min-width: min-content;
}

.is-outdated {
  color: red !important;
}

.level {
  width: 12rem;
  text-align: right;
}

.sim-count {
  width: 12rem;
  text-align: right;
}
</style>
